import { Model } from "@/store/models";

export type FormFieldType =
  | "text"
  | "long-text"
  | "checkbox"
  | "select"
  | "time-slot"
  | "file"
  | "picture"
  | "single-select";

interface IsDisabledFn {
  (object: object | undefined): boolean;
}

export type FormFieldOptions = {
  type: FormFieldType;
  model?: string;
  required?: boolean;
  limit?: number;
  data?: Record<string, string>;
  lockingCheck?: boolean;
  disabled?: IsDisabledFn;
};

export const DefaultPictureMimeType = ["image/gif", "image/jpeg", "image/png"];
interface ChangeFileCallbackFn {
  (file: File): void;
}

export type FormFieldFileOptions = FormFieldOptions & {
  accept?: string;
  sizeLimit?: string;
  changeCallbackFn?: ChangeFileCallbackFn;
};

export type PictureForm = "square" | "standard";
export type FormFieldPictureOptions = FormFieldFileOptions & {
  form?: PictureForm;
  maxHeight?: number;
  maxWidth?: number;
};

export type FormFields = Record<
  string,
  FormFieldOptions | FormFieldFileOptions | FormFieldPictureOptions
>;
export type FormSteps = Record<string, FormFields>;
export type FormMode = "edit" | "create";

export class PictureError extends Error {
  constructor(cause: string) {
    super(`Picture Error`, { cause });
  }
}

export class PictureMultipleErrors extends Error {
  constructor(cause: PictureError[]) {
    super(`Picture Multiple Errors`, { cause });
  }
}

export async function checkPicture(
  element: HTMLInputElement,
  options: FormFieldPictureOptions
): Promise<PictureError | PictureMultipleErrors | void> {
  if (options.maxHeight === undefined && options.maxWidth === undefined) {
    return Promise.resolve();
  }
  const img = new Image();
  const file = element.files?.item(0);
  return new Promise((resolve, reject) => {
    img.onload = () => {
      try {
        imageSizeCheck(img, options);
        resolve();
      } catch (e) {
        if (options.lockingCheck) {
          return reject(e);
        }
        resolve(e as PictureError | PictureMultipleErrors);
      }
    };
    img.onerror = reject;
    img.src = window.URL.createObjectURL(file as Blob);
  });
}

export function checkIsPicture(
  element: HTMLInputElement,
  options?: FormFieldFileOptions
): boolean {
  const accept = options?.accept?.split(" ") || DefaultPictureMimeType;
  const file = element.files?.item(0);
  return file !== undefined && file !== null && accept.includes(file["type"]);
}

export function imageSizeCheck(
  img: HTMLImageElement,
  options: FormFieldPictureOptions
): void {
  const shape = options.form || "standard";
  const size = options.maxHeight || options.maxWidth || 0;
  const errors: PictureError[] = [];
  switch (shape) {
    case "square":
      if (img.width !== img.height || img.height > size) {
        throw new PictureError(
          `Picture must be with square dimension and smaller than ${size}px`
        );
      }
      break;
    default:
      if (options.maxHeight && img.height > options.maxHeight) {
        errors.push(
          new PictureError(
            `Picture height must be smaller than ${options.maxHeight}px`
          )
        );
      }
      if (options.maxWidth && img.width > options.maxWidth) {
        errors.push(
          new PictureError(
            `Picture width must be smaller than ${options.maxWidth}px`
          )
        );
      }
      if (errors.length > 1) {
        throw new PictureMultipleErrors(errors);
      }
      if (errors.length > 0) {
        throw errors[0];
      }
  }
}

export function imagePreview(file: File, destination: HTMLImageElement): void {
  const reader = new FileReader();
  reader.onload = function (evt: ProgressEvent<FileReader>) {
    destination.setAttribute("src", (evt.target?.result as string) || "");
  };
  reader.readAsDataURL(file);
}

function cleanImagePreview(destination: HTMLImageElement): void {
  destination.setAttribute("src", "#");
}

export type fileSizeMeasure = "ko" | "Mo";

export function convertFileSize(size: string): number {
  const sizeMeasure = size.slice(-2) as fileSizeMeasure;
  const sizeValue = parseFloat(size.substring(0, size.length - 2));
  switch (sizeMeasure) {
    case "ko":
      return Math.round(sizeValue * 1024);
    case "Mo":
      return Math.round(sizeValue * 1024 * 1024);
    default:
      return Math.round(parseFloat(size));
  }
}

export function setFieldError(fieldName: string, parentBubble?: boolean): void {
  const bubble = parentBubble || false;
  const field = document.getElementById(fieldName) as HTMLElement;
  const help = document.getElementById(`${fieldName}Help`) as HTMLElement;
  const parent = field.parentElement as HTMLElement;
  const label = document.getElementById(
    `${fieldName}TextField`
  ) as HTMLLabelElement;
  if (!bubble && field) {
    field.classList.add("is-invalid");
    field.classList.remove("is-valid");
  }
  if (bubble && parent) {
    parent.classList.add("is-invalid");
    parent.classList.remove("is-valid");
  }
  if (label) {
    label.classList.add("is-invalid");
    label.classList.remove("is-valid");
  }
  if (help) {
    help.classList.add("invalid-feedback");
  }
}

export function setFieldClean(fieldName: string, parentBubble?: boolean): void {
  const bubble = parentBubble || false;
  const field = document.getElementById(fieldName) as HTMLElement;
  const help = document.getElementById(`${fieldName}Help`) as HTMLElement;
  const parent = field.parentElement as HTMLElement;
  const label = document.getElementById(
    `${fieldName}TextField`
  ) as HTMLLabelElement;
  if (!bubble && field) {
    field.classList.remove("is-invalid");
    field.classList.remove("is-valid");
  }
  if (bubble && parent) {
    parent.classList.remove("is-invalid");
    parent.classList.remove("is-valid");
  }
  if (label) {
    label.classList.remove("is-invalid");
    label.classList.remove("is-valid");
  }
  if (help) {
    help.classList.remove("invalid-feedback");
  }
}

export function setFieldSuccess(
  fieldName: string,
  parentBubble?: boolean
): void {
  const bubble = parentBubble || false;
  const field = document.getElementById(fieldName) as HTMLElement;
  const help = document.getElementById(`${fieldName}Help`) as HTMLElement;
  const parent = field.parentElement as HTMLElement;
  const required = field.attributes.getNamedItem("required") !== null;
  const label = document.getElementById(
    `${fieldName}TextField`
  ) as HTMLLabelElement;
  if (!bubble && field) {
    field.classList.remove("is-invalid");
    if (required) {
      field.classList.add("is-valid");
    }
  }
  if (bubble && parent) {
    parent.classList.remove("is-invalid");
    if (required) {
      parent.classList.add("is-valid");
    }
  }
  if (label) {
    label.classList.remove("is-invalid");
    if (required) {
      label.classList.add("is-valid");
    }
  }
  if (help) {
    help.classList.remove("invalid-feedback");
  }
}

export type ChangeFileResult = {
  id: string;
  value?: File | null;
};

export function fileChangeEvent(
  evt: Event,
  options: FormFieldFileOptions
): ChangeFileResult {
  const field = evt.target as HTMLInputElement;
  const files = field.files;
  const fileSizeLimit = convertFileSize(options.sizeLimit || "00");
  const result: ChangeFileResult = { id: field.id, value: undefined };
  let sizeError: PictureError;
  if (files === undefined || files?.length === 0) {
    setFieldError(field.id, true);
    return result;
  }

  if ((files?.item(0)?.size || 0) > fileSizeLimit) {
    if (options.lockingCheck) {
      setFieldError(field.id, true);
      return result;
    }
    sizeError = new PictureError(
      `File weight is to hight, requested limite is ${options.sizeLimit}`
    );
  }

  const file = files?.item(0);
  const label = document.getElementById(
    `${field.id}TextField`
  ) as HTMLLabelElement;
  if (options.type === "picture") {
    checkPicture(field, options)
      .then((error: PictureError | PictureMultipleErrors | void) => {
        const img = document.getElementById(
          `${field.id}Preview`
        ) as HTMLImageElement;
        img.classList.remove("img-loader");
        if (file) {
          imagePreview(file, img);
          // img.classList.remove("visually-hidden");
          img.classList.remove("img-empty");
          error || sizeError
            ? setFieldError(field.id, true)
            : setFieldSuccess(field.id, true);
          label.innerText = files?.item(0)?.name || "";
          result.value = files?.item(0);
          if (typeof options.changeCallbackFn === "function") {
            options.changeCallbackFn(file);
          }
          return;
        }
        img.src = "#";
        img.classList.add("img-empty");
        // img.classList.add("visually-hidden");
      })
      .catch(() => {
        setFieldError(field.id, true);
      });
    return result;
  }
  setFieldSuccess(field.id, true);
  label.innerText = file?.name || "";
  result.value = file;
  if (file && typeof options.changeCallbackFn === "function") {
    options.changeCallbackFn(file);
  }
  return result;
}

export function validateFormStep(
  form: FormSteps,
  currentStepId: number,
  model?: Model,
  formId = ""
): boolean {
  const currentStepKey = Object.keys(form)[currentStepId];
  const currentStep = form[currentStepKey];
  return validateForm(currentStep, model, formId);
}

export function validateForm(
  fields: FormFields,
  model?: Model,
  formId = ""
): boolean {
  if (model === undefined) {
    return true;
  }
  let hasError = false;
  Object.entries(fields).forEach((entry) => {
    const fieldName = entry[0];
    const options = entry[1];
    const value = model.get(fieldName);
    if (options.required === true) {
      switch (options.type) {
        case "text":
        case "long-text":
          if (value === undefined || value === "") {
            hasError = true;
            return setFieldError(`${formId}${fieldName}`);
          }
          break;
        default:
          if (value === undefined) {
            hasError = true;
            return setFieldError(
              `${formId}${fieldName}`,
              options.type === "picture"
            );
          }
      }
    }
    return setFieldSuccess(`${formId}${fieldName}`, options.type === "picture");
  });
  return !hasError;
}

function cleanPictureField(fieldName: string, formId: string) {
  const img = document.getElementById(
    `${formId}${fieldName}Preview`
  ) as HTMLImageElement;
  const label = document.getElementById(
    `${formId}${fieldName}TextField`
  ) as HTMLLabelElement;
  const field = document.getElementById(
    `${formId}${fieldName}`
  ) as HTMLInputElement;
  if (img) {
    cleanImagePreview(img);
    img.classList.add("img-empty");
  }
  if (label) {
    label.innerText = field ? field.placeholder : "";
  }
  if (field) {
    field.value = "";
  }
}

export function cleanForm(fields: FormFields, formId = ""): void {
  Object.entries(fields).forEach((entry) => {
    const fieldName = entry[0];
    const options = entry[1];
    switch (options.type) {
      case "picture":
        cleanPictureField(fieldName, formId);
        break;
      default:
        (
          document.getElementById(`${formId}${fieldName}`) as HTMLInputElement
        ).value = "";
    }
  });
}
